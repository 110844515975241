import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($filter: CrmNotificationsFilter) {
    crm {
      userOptions {
        notifications(filter: $filter) {
          id
          title
          message
          readAt
          createdAt
          remarks
          type
          user {
            entityContext {
              ... on CrmLead {
                name
              }
              ... on CrmUser {
                lastName
                firstName
                twilio {
                  projectedAddress
                }
              }
            }
            entityContextType
          }
          data {
            ... on CrmAppointment {
              id
            }
            ... on CrmTwilioConversationMessage {
              contentSid
              attributes
            }
            ... on IdentityNote {
              contextId
              context
              contextPath
              content
              mentions {
                mentionId
                mentionContext {
                  ... on IdentityUser {
                    email
                    lastName
                    firstName
                    twilio {
                      projectedAddress
                    }
                  }
                  ... on CrmLead {
                    email
                    address {
                      streetNo
                      streetName
                      city
                      state
                    }
                    name
                    contactNo
                  }
                  ... on ProjectManagementProject {
                    id
                    customer {
                      address
                      firstName
                      lastName
                      email
                      phone
                    }
                  }
                }
              }
            }
            ... on CrmRecording {
              callSid
              id
              dialer
              callerData {
                ... on CrmUser {
                  email
                  lastName
                  firstName
                  twilio {
                    projectedAddress
                  }
                }
                ... on CrmLead {
                  name
                }
              }
            }
            ... on CrmCallPayload {
              sid
              id
              caller
              callerData {
                ... on CrmUser {
                  email
                  lastName
                  firstName
                  twilio {
                    projectedAddress
                  }
                }
                ... on CrmLead {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LEAD_NOTE_NOTIFICATIONS = gql`
  query GetLeadNoteNotifications($filter: CrmLeadFilter) {
    crm {
      leadsPaged(filter: $filter) {
        leads {
          activityFeed {
            id
            title
            message
            readAt
            createdAt
            remarks
            type
            data {
              ... on IdentityNote {
                content
                contextId
                context
                createdAt
                createdBy {
                  firstName
                  lastName
                }
                mentions {
                  mentionId
                  mentionContext {
                    ... on IdentityUser {
                      email
                      lastName
                      firstName
                      twilio {
                        projectedAddress
                      }
                    }
                    ... on CrmLead {
                      email
                      address {
                        streetNo
                        streetName
                        city
                        state
                      }
                      name
                      contactNo
                    }
                    ... on ProjectManagementProject {
                      id
                      customer {
                        address
                        firstName
                        lastName
                        email
                        phone
                      }
                    }
                  }
                }
              }
            }
            user {
              entityContextType
              entityContext {
                ... on CrmLead {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
