import { gql } from '@apollo/client';

export const GET_CALL_RECORDINGS_PAYLOADS_PAGED = gql`
  query CallRecordingsAndPayloadsPage($filter: CrmCallRecordingsAndPayloadsInput, $pagination: PaginationInput) {
    crm {
      userOptions {
        callRecordingsAndPayloadsPage(filter: $filter, pagination: $pagination) {
          totalCount
          callRecordingsAndPayloads {
            ... on CrmRecording {
              id
              callSid
              dialer
              receiver
              duration
              summary
              startTime
              mediaUrl
              endTime
              type
              recordingCreatedAt: createdAt
              callerData {
                ... on CrmLead {
                  contactNo
                  name
                  __typename
                  id
                  email
                  address {
                    id
                    streetNo
                    streetName
                    city
                    state
                    zipCode
                    country
                    lat
                    lng
                    createdAt
                  }
                  assignedTo {
                    firstName
                    lastName
                  }
                  leadForm {
                    platform
                  }
                  interestType {
                    interestTypeName
                  }
                }
                ... on CrmUser {
                  firstName
                  lastName
                  twilio {
                    projectedAddress
                  }
                  __typename
                }
              }
              receiverData {
                ... on CrmLead {
                  name
                  contactNo
                  __typename
                }
                ... on CrmUser {
                  lastName
                  firstName
                  twilio {
                    projectedAddress
                  }
                  __typename
                }
              }
            }
            ... on CrmCallPayload {
              id
              sid
              direction
              caller
              receiver
              status
              answeredBy
              payload
              duration
              recordingUrl
              payloadCreatedAt: createdAt
              callerData {
                ... on CrmLead {
                  contactNo
                  name
                  __typename
                  id
                  email
                  address {
                    id
                    streetNo
                    streetName
                    city
                    state
                    zipCode
                    country
                    lat
                    lng
                    createdAt
                  }
                  assignedTo {
                    firstName
                    lastName
                  }
                  leadForm {
                    platform
                  }
                  interestType {
                    interestTypeName
                  }
                }
                ... on CrmUser {
                  firstName
                  lastName
                  twilio {
                    projectedAddress
                  }
                  __typename
                }
              }
              receiverData {
                ... on CrmLead {
                  name
                  contactNo
                  __typename
                }
                ... on CrmUser {
                  lastName
                  firstName
                  twilio {
                    projectedAddress
                  }
                  __typename
                }
              }
            }
          }
        }
      }
    }
  }
`;
