import { List, Skeleton, Stack, Grid, Box } from '@mui/material';
import React from 'react';

const NotificationSkeleton = () => (
  <List sx={{ padding: 2 }}>
    <Grid container flexDirection={'column'}>
      <Grid item mb={2}>
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      </Grid>
      <Grid item>
        <Stack spacing={2}>
          {[...Array(4)].map((_, index) => (
            <Box key={index} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={210} height={80} sx={{ flex: 1, ml: 2 }} />
            </Box>
          ))}
        </Stack>
      </Grid>
    </Grid>
  </List>
);

export default NotificationSkeleton;
