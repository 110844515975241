import { gql } from '@apollo/client';

export const GET_AVAILABLE_TWILIO_NUMBERS = gql`
  query GetAvailableTwilioNumbers($filter: IdentityTwilioAvailableNumberPagedFilter) {
    identity {
      admin {
        twilio {
          availableNumbers(filter: $filter) {
            phoneNumber
            friendlyName
            lata
            rateCenter
            latitude
            longitude
            locality
            region
            postalCode
            isoCountry
            addressRequirements
            beta
            capabilities {
              voice
              sms
              mms
            }
          }
        }
      }
    }
  }
`;
