import { gql } from '@apollo/client';

export const GET_CALL_PAYLOADS = gql`
  query GetCallPayloads($filter: CrmCallPayloadFilter) {
    crm {
      userOptions {
        callPayloads(filter: $filter) {
          id
          sid
          direction
          caller
          receiver
          status
          answeredBy
          payload
          duration
          createdAt
        }
      }
    }
  }
`;

export const GET_CALL_PAYLOADS_PAGED = gql`
  query CallPayloadsPaged($pagination: PaginationInput, $filter: CrmCallPayloadFilter) {
    crm {
      userOptions {
        callPayloadsPaged(pagination: $pagination, filter: $filter) {
          totalCount
          callPayloads {
            id
            sid
            direction
            caller
            receiver
            status
            answeredBy
            payload
            duration
            createdAt
            callerData {
              ... on CrmLead {
                contactNo
                name
                __typename
              }
              ... on CrmUser {
                firstName
                lastName
                twilio {
                  projectedAddress
                }
                __typename
              }
            }
            receiverData {
              ... on CrmLead {
                name
                contactNo
                __typename
              }
              ... on CrmUser {
                lastName
                firstName
                twilio {
                  projectedAddress
                }
                __typename
              }
            }
          }
        }
      }
    }
  }
`;
