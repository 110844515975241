import { gql } from '@apollo/client';

export const GET_INTEREST_TYPES = gql`
  query GetInterestTypes {
    crm {
      preferences {
        interestTypes {
          id
          interestTypeName
          createdAt
        }
      }
    }
  }
`;
