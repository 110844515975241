import { gql } from '@apollo/client';

export const BUY_TWILIO_NUMBER = gql`
  mutation BuyAccountNumber($input: IdentityBuyAccountNumberInput!) {
    identity {
      admin {
        twilio {
          buyAccountNumber(input: $input) {
            success
            accounts {
              friendlyName
              phoneNumber
              sid
            }
          }
        }
      }
    }
  }
`;
