// Themes
export /**
eeeeeee *
eeeeeee
eeeeeee
eeeeeee
eeeeeee
eeeeeee */
const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO',
};
