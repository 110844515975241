import React from 'react';

type TextHighlighterProps = {
  message: string;
};

const TextHighlighter = (props: TextHighlighterProps) => {
  const { message } = props;

  if (!message) {
    return null;
  }

  const nameEndIndex = message.indexOf('mentioned');
  const name = message.substring(0, nameEndIndex).trim();
  const restOfSentence = message.substring(nameEndIndex);

  return (
    <span>
      <strong>{name}</strong> {restOfSentence}
    </span>
  );
};

export default TextHighlighter;
