import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { Checkbox, FormControlLabel, Button, TextField as MuiTextField, Grid } from '@mui/material';
import { spacing } from '@mui/system';
import useAuth from '../../hooks/useAuth';
import { FormikPasswordField } from '../../custom/components/formElements/FormikPasswordField';
import NucleusAlert from '../../custom/components/NucleusAlert';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const location = useLocation();
  const [isFromResetPassword, setIsFromResetPassword] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (location.state?.fromResetPassword) {
      setIsFromResetPassword(true);
      setShowAlert(true);

      timer = setTimeout(() => {
        setShowAlert(false);
        navigate(location.pathname, { state: { ...location.state, fromResetPassword: false } });
      }, 5000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [location.state]);

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().max(255).required('Username is required'),
          password: Yup.string().max(255).required('Password is required'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await signIn({ username: values.email, password: values.password });

            navigate('/crm/contacts');
          } catch (error: any) {
            const message = error.message || 'Something went wrong';

            if (error.message === 'New password required') {
              navigate('/auth/new-password');
            }
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && <NucleusAlert severity="warning" message={errors.submit} />}
            <TextField
              type="text"
              name="email"
              label="Username"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <Field
              component={FormikPasswordField}
              type="password"
              field="password"
              label="Password"
              {...{ touched, values, errors, handleBlur, handleChange }}
              helperText={touched.password && errors.password}
            />
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            <Button sx={{ mb: 2 }} type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
              Sign in
            </Button>

            <Button style={{ marginTop: 2 }} component={Link} to="/auth/reset-password" fullWidth color="primary">
              Forgot password
            </Button>
          </form>
        )}
      </Formik>

      <Grid container style={{ width: '100%' }}>
        {showAlert && isFromResetPassword ? (
          <NucleusAlert severity="success" message="Password has been reset successfully." style={{ width: '100%' }} />
        ) : null}
      </Grid>
    </React.Fragment>
  );
}

export default SignIn;
