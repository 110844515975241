import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import styled from '@emotion/styled';
import { Avatar as MuiAvatar, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, SvgIcon } from '@mui/material';
import NucleusTooltip from '../../../custom/components/NucleusTooltip';
import { Link } from 'react-router-dom';

const Avatar = styled(MuiAvatar)`
  background: ${props => props.theme.palette.primary.main};
`;

function Notification({
  title,
  description,
  initials,
  Icon,
  link,
  notificationData,
  onClick,
  readAt,
}: {
  title: string | React.ReactNode;
  description: string;
  initials?: string;
  Icon?: React.ElementType;
  link: string;
  notificationData?: Object;
  onClick: () => void;
  readAt: string | null;
}) {
  return (
    <ListItem divider>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <ListItemAvatar>
            <Avatar>
              {initials ? (
                <span>{initials}</span> // Display initials
              ) : (
                Icon && (
                  <SvgIcon fontSize="small">
                    <Icon />
                  </SvgIcon>
                )
              )}
            </Avatar>
          </ListItemAvatar>
        </Grid>
        <Grid item xs={9}>
          <Grid item xs={9}>
            <Link
              to={link}
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer"
              onClick={event => {
                event.stopPropagation();
                localStorage.setItem('notificationData', JSON.stringify(notificationData));
                onClick();
              }}
            >
              <ListItemText
                primary={title}
                primaryTypographyProps={{
                  variant: 'subtitle2',
                  color: 'textPrimary',
                }}
                secondary={description}
              />
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={1} textAlign={'right'}>
          <NucleusTooltip title={readAt !== null ? 'Mark as unread' : 'Mark as read'} placement="left">
            <IconButton
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                onClick();
              }}
              aria-label="Unread status"
              style={{ padding: 0, margin: 0, border: readAt !== null ? '1px solid grey' : 'none' }}
            >
              <FiberManualRecordIcon sx={{ color: readAt !== null ? '#F5F5F5' : 'red', fontSize: 15 }} />
            </IconButton>
          </NucleusTooltip>
        </Grid>
      </Grid>
    </ListItem>
  );
}

export default Notification;
