import { gql } from '@apollo/client';

export const GET_CONVERSATIONS = gql`
  query ConversationThread($filter: CrmConversationThreadFilter) {
    crm {
      userOptions {
        userConversations {
          meta {
            page
            pageSize
            firstPageUrl
            previousPageUrl
            url
            nextPageUrl
            key
          }
          conversations {
            userSid
            friendlyName
            conversationSid
            dateUpdated
            unreadMessagesCount
            lastReadMessageIndex
            conversationThread(filter: $filter) {
              messages {
                body
                index
                author
                dateUpdated
                participantSid
                conversationSid
                url
                dateCreated
                contentSid
                sid
                attributes
                isMe
                media {
                  category
                  size
                  fileName
                  contentType
                  sid
                  contentUrl
                  contentTemporaryUrl
                }
              }
              meta {
                page
                pageSize
                firstPageUrl
                previousPageUrl
                url
                nextPageUrl
                key
              }
            }
            participants {
              dateUpdated
              lastReadMessageIndex
              lastReadTimestamp
              conversationSid
              accountSid
              url
              dateCreated
              sid
              attributes
              identity
              messagingBinding {
                projectedAddress
                address
                type
              }
            }
            emails {
              id
              receiver
              sender
              subject
              body
              createdAt
              isMe
            }
            recordings {
              id
              callSid
              dialer
              receiver
              duration
              summary
              startTime
              mediaUrl
              createdAt
              endTime
              type
            }
          }
        }
      }
    }
  }
`;

export const GET_USERS_LEADS = gql`
  query UserLeads {
    crm {
      leads {
        id
        name
        contactNo
        email
      }
    }
    identity {
      users {
        id
        firstName
        lastName
        twilio {
          projectedAddress
        }
        email
      }
    }
  }
`;

export const GET_NEXT_CONVERSATION_THREAD = gql`
  query NextConversationThread($filter: CrmConversationThreadFilter) {
    crm {
      userOptions {
        userConversations {
          conversations {
            conversationThread(filter: $filter) {
              messages {
                body
                index
                author
                dateUpdated
                participantSid
                conversationSid
                url
                dateCreated
                contentSid
                sid
                attributes
                isMe
                isExternal
                sender
              }
              meta {
                page
                pageSize
                firstPageUrl
                previousPageUrl
                url
                nextPageUrl
                key
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_NEXT_CONVERSATION_COLLECTION = gql`
  query UserConversations($filter: CrmTwilioConversationFilter) {
    crm {
      userOptions {
        userConversations(filter: $filter) {
          meta {
            page
            pageSize
            firstPageUrl
            previousPageUrl
            url
            nextPageUrl
            key
          }
          conversations {
            conversationSid
            conversationThread {
              meta {
                page
                pageSize
                firstPageUrl
                previousPageUrl
                url
                nextPageUrl
                key
              }
              messages {
                body
                index
                author
                dateUpdated
                media {
                  category
                  size
                  fileName
                  contentType
                  sid
                  contentUrl
                  contentTemporaryUrl
                }
                participantSid
                conversationSid
                url
                dateCreated
                contentSid
                sid
                attributes
                isMe
                isExternal
                sender
              }
            }
            dateUpdated
            emails {
              id
              receiver
              sender
              subject
              body
              createdAt
              isMe
            }
            friendlyName
            lastReadMessageIndex
            participants {
              lastReadMessageIndex
              dateUpdated
              lastReadTimestamp
              conversationSid
              accountSid
              url
              dateCreated
              sid
              attributes
              identity
              messagingBinding {
                projectedAddress
                address
                type
              }
              isMe
            }
            recordings {
              accountSid
              apiVersion
              callSid
              conferenceSid
              dateCreated
              dateUpdated
              startTime
              duration
              sid
              price
              priceUnit
              status
              channels
              source
              errorCode
              uri
              encryptionDetails
              subresourceUris {
                addOnResults
                transcriptions
              }
              mediaUrl
              from
              to
              summary
            }
            unreadMessagesCount
            userSid
          }
        }
      }
    }
  }
`;
