import React from 'react';
import styled from '@emotion/styled';

import { Typography } from '@mui/material';

import { SidebarItemsType } from '../../types/sidebar';
import SidebarNavList from './SidebarNavList';
import { useSidebarContext } from '../../contexts/sidebar/SidebarContext';

export const Title = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  font-size: ${props => props.theme.typography.caption.fontSize};
  padding: ${props => props.theme.spacing(4)} ${props => props.theme.spacing(7)} ${props => props.theme.spacing(1)};
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
`;

type SidebarNavSectionProps = {
  className?: Element;
  component?: React.ElementType;
  pages: SidebarItemsType[];
  title?: string;
};

/**
 *
 * @param props
 */
const SidebarNavSection: React.FC<SidebarNavSectionProps> = props => {
  const { title, pages, className, component: Component = 'nav', ...rest } = props;
  const sideBar = useSidebarContext();
  const DefaultTitle = sideBar?.sidebarNavSection?.sidebarNavSectionComponentTheme?.Title ?? Title;

  const [sidebarNavListCount, setSidebarNavListCount] = React.useState(0);

  return (
    <Component {...rest}>
      {sidebarNavListCount > 0 && title && <DefaultTitle variant="subtitle2">{title}</DefaultTitle>}
      <SidebarNavList pages={pages} depth={0} setSidebarNavListCount={setSidebarNavListCount} />
    </Component>
  );
};

export default SidebarNavSection;
