import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import hasRequiredPermission from './utils/permissions';
import { UserIdentityContext } from '../../custom/contexts/UserIdentityContext';
import NucleusLoader from '../../custom/NucleusLoader';

interface PermissionGuardProps {
  children: React.ReactNode;
  requiredPermission: string;
  fallbackPath?: string; // Optional path to redirect to if permission is denied
}

/**
 * A React functional component that guards its children based on user permissions.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render if the user has the required permission.
 * @param {string} props.requiredPermission - The permission required to access the children.
 * @param {string} [props.fallbackPath='/unauthorized'] - The path to redirect to if the user does not have the required permission. Defaults to '/unauthorized'.
 * @returns {JSX.Element} - The child components if the user has the required permission, otherwise a redirect to the fallback path.
 */
const PermissionGuard: React.FC<PermissionGuardProps> = ({
  children,
  requiredPermission,
  fallbackPath = '/unauthorized', // Default to an unauthorized page
}: {
  children: React.ReactNode;
  requiredPermission: string;
  fallbackPath?: string;
}): JSX.Element => {
  const { userIdentity } = React.useContext(UserIdentityContext) || {};
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userIdentity) {
      setIsLoading(false);
    }
  }, [userIdentity]);

  if (isLoading) {
    return <NucleusLoader />;
  }

  const userPermissions = userIdentity?.permissions?.map(permission => permission.name) ?? [''];

  if (!hasRequiredPermission(userPermissions, requiredPermission)) {
    return <Navigate to={fallbackPath} replace />;
  }

  return <>{children}</>;
};

export default PermissionGuard;
