import React from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CacheProvider } from '@emotion/react';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './i18n';
import createTheme from './theme';
import routes from './routes';
import { store } from './redux/store';

import useTheme from './hooks/useTheme';
import createEmotionCache from './utils/createEmotionCache';

// import { AuthProvider } from './contexts/JWTContext';
// import { AuthProvider } from './contexts/FirebaseAuthContext';
import { HubProvider } from './custom/contexts/HubContext';
import ErrorBoundary from './custom/components/ErrorBoundary';
// import { AuthProvider } from "./contexts/Auth0Context";
import { AuthProvider } from './contexts/CognitoContext';
import { AMDProvider, CallStatusProvider } from './custom/pages/task/context/CallStatusContext';
import { TaskFormProvider, TaskToCreateProvider } from './custom/pages/task/context/SubmitTaskFormContext';
import { ContactProfileProvider } from './custom/pages/contacts/profile/context/ProfileActivityContext';
import { Provider } from 'react-redux';
import { VoiceTokenProvider } from './custom/contexts/TwilioContext';
import { UserIdentityProvider } from './custom/contexts/UserIdentityContext';

const clientSideEmotionCache = createEmotionCache();

/**
 * App
 * @param emotionCache - Emotion cache
 */
function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const location = useLocation();

  const isAuthBypassedRoute = location.pathname.startsWith('/proposal/');

  const { theme } = useTheme();

  return (
    <ErrorBoundary>
      <CacheProvider value={emotionCache}>
        <HelmetProvider>
          <Helmet titleTemplate="%s | Nucleus" defaultTitle="Nucleus Solar - CRM" />
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MuiThemeProvider theme={createTheme(theme)}>
                {isAuthBypassedRoute ? (
                  // No AuthProvider for this route
                  <>
                    <HubProvider>
                      <UserIdentityProvider>
                        <VoiceTokenProvider>
                          <CallStatusProvider>
                            <AMDProvider>
                              <TaskFormProvider>
                                <TaskToCreateProvider>
                                  <ContactProfileProvider>{content}</ContactProfileProvider>
                                </TaskToCreateProvider>
                              </TaskFormProvider>
                            </AMDProvider>
                          </CallStatusProvider>
                        </VoiceTokenProvider>
                      </UserIdentityProvider>
                    </HubProvider>
                  </>
                ) : (
                  // With AuthProvider for other routes
                  <AuthProvider>
                    <HubProvider>
                      <UserIdentityProvider>
                        <VoiceTokenProvider>
                          <CallStatusProvider>
                            <AMDProvider>
                              <TaskFormProvider>
                                <TaskToCreateProvider>
                                  <ContactProfileProvider>{content}</ContactProfileProvider>
                                </TaskToCreateProvider>
                              </TaskFormProvider>
                            </AMDProvider>
                          </CallStatusProvider>
                        </VoiceTokenProvider>
                      </UserIdentityProvider>
                    </HubProvider>
                  </AuthProvider>
                )}
              </MuiThemeProvider>
            </LocalizationProvider>
          </Provider>
        </HelmetProvider>
      </CacheProvider>
    </ErrorBoundary>
  );
}

export default App;
