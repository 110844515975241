import { gql } from '@apollo/client';

export const GET_ROOF_TYPES = gql`
  query RoofTypes {
    crm {
      preferences {
        roofTypes {
          id
          roofTypeName
          createdAt
        }
      }
    }
  }
`;
