import { gql } from '@apollo/client';

export const GET_TASK_SCHEDULES_PAGED = gql`
  query TaskSchedulesPaged(
    $pagination: PaginationInput
    $filter: CrmTaskScheduleFilter
    $search: CrmTaskScheduleSearch
  ) {
    crm {
      taskSchedulesPaged(pagination: $pagination, filter: $filter, search: $search) {
        totalCount
        taskSchedules {
          id
          taskTitle
          dueDate
          createdAt
          createdBy {
            id
            email
            firstName
            lastName
          }
          taskScaffoldTypeId
          remarks
          lead {
            id
            name
            email
            contactNo
            hasTaskSequence
            isContacted
            address {
              id
              streetNo
              streetName
              city
              state
              zipCode
              country
              lat
              lng
              createdAt
            }
            leadGuide {
              id
              title
              contentBody
              createdAt
            }
            leadForm {
              id
              platform
            }
            interestType {
              id
              interestTypeName
            }
            isCallableByAssignedUser
          }
          taskType {
            id
            taskTypeName
          }
          status
        }
      }
    }
  }
`;

export const GET_TASK_SCHEDULES = gql`
  query TaskSchedules($filter: CrmTaskScheduleFilter) {
    crm {
      taskSchedules(filter: $filter) {
        id
        taskTitle
        dueDate
        createdAt
        createdBy {
          id
          email
          firstName
          lastName
        }
        taskScaffoldTypeId
        remarks
        lead {
          id
          name
          email
          contactNo
          hasTaskSequence
          isContacted
          address {
            id
            streetNo
            streetName
            city
            state
            zipCode
            country
            lat
            lng
            createdAt
          }
          leadGuide {
            id
            title
            contentBody
            createdAt
          }
          leadForm {
            id
            platform
          }
          interestType {
            id
            interestTypeName
          }
          isCallableByAssignedUser
        }
        taskType {
          id
          taskTypeName
        }
        status
      }
    }
  }
`;

export const GET_TASK_SCHEDULE_HISTORY = gql`
  query TaskSchedulesHistory($crmTaskScheduleFilter: CrmTaskScheduleFilter) {
    crm {
      taskSchedulesPaged(filter: $crmTaskScheduleFilter) {
        taskSchedules {
          id
          leadId
          remarks
          history {
            id
            eventSubject
            eventType
            eventContextId
            identity
            timestamp
            details
            summary
          }
          notes {
            id
            createdBy {
              firstName
              lastName
            }
            mentions {
              mentionId
              mentionContext {
                ... on IdentityUser {
                  email
                  lastName
                  firstName
                  twilio {
                    projectedAddress
                  }
                }
                ... on CrmLead {
                  email
                  address {
                    streetNo
                    streetName
                    city
                    state
                  }
                  name
                  contactNo
                }
                ... on ProjectManagementProject {
                  id
                  customer {
                    address
                    firstName
                    lastName
                    email
                    phone
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
