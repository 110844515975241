import { gql } from '@apollo/client';

export const GET_APPOINTMENTS = gql`
  query GetAppointments($filter: CrmAppointmentFilter) {
    crm {
      appointments(filter: $filter) {
        hasMeetingLink
        id
        appointmentTypeId
        appointmentType {
          id
          appointmentTypeName
          displayName
          createdAt
        }
        appointmentLocationId
        appointmentLocation {
          id
          appointmentLocationName
          displayName
          createdAt
        }
        assignedTo {
          id
          email
          firstName
          lastName
          tenantId
        }
        createdBy {
          id
          email
          firstName
          lastName
          tenantId
        }
        startTime
        appointmentGuests {
          name
        }
        leadId
      }
    }
  }
`;

export const GET_APPOINTMENT_TYPES = gql`
  query AppointmentTypes {
    crm {
      preferences {
        appointmentTypes {
          id
          appointmentTypeName
          displayName
        }
      }
    }
  }
`;

export const GET_APPOINTMENT_LOCATIONS = gql`
  query AppointmentLocations {
    crm {
      preferences {
        appointmentLocations {
          id
          appointmentLocationName
          displayName
        }
      }
    }
  }
`;

export const APPOINTMENT_TASK_FORM_GET_APPOINTMENT = gql`
  query AppointmentTaskFormGetAppointment($filter: CrmAppointmentFilter) {
    crm {
      appointments(filter: $filter) {
        id
        leadId
        appointmentType {
          id
          appointmentTypeName
          displayName
        }
      }
    }
  }
`;
