import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query Users {
    identity {
      users {
        id
        email
        firstName
        lastName
        twilio {
          sendGridVerified
          projectedAddress
        }
        roles {
          id
          name
        }
        connectedServices {
          service
          status
          info
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query IdentityUsersFilter($filter: IdentityUserFilter) {
    identity {
      users(filter: $filter) {
        id
        email
        firstName
        lastName
        twilio {
          projectedAddress
          sendGridVerified
          voiceMessages {
            service
            url
            publicId
            messageType
          }
        }
        permissions {
          name
        }
        roles {
          name
        }
      }
    }
  }
`;

export const APPOINTMENT_TASK_FORM_GET_USER = gql`
  query AppointmentTaskFormGetUsers($filter: IdentityUserFilter) {
    identity {
      users(filter: $filter) {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const GET_INTEREST_TYPES_NEW_LEADS = gql`
  query InterestTypesNewLeads {
    crm {
      preferences {
        interestTypes {
          id
          interestTypeName
          createdAt
        }
      }
    }
  }
`;

export const GET_ROOF_TYPES_NEW_LEADS = gql`
  query RoofTypesNewLeads {
    crm {
      preferences {
        roofTypes {
          id
          roofTypeName
          createdAt
        }
      }
    }
  }
`;

export const GET_USERS_MENTION = gql`
  query GET_USERS_MENTION($filter: IdentityUserPagedFilter) {
    identity {
      usersPaged(filter: $filter) {
        email
        firstName
        id
        lastName
      }
    }
  }
`;

export const GET_PAGED_USERS = gql`
  query GET_PAGED_USERS($filter: IdentityUserPagedFilter) {
    identity {
      usersPaged(filter: $filter) {
        email
        firstName
        id
        lastName
      }
    }
  }
`;

export const GET_USER_VOICE_MESSAGES = gql`
  query GetUserVoiceMessages($filter: IdentityUserFilter) {
    identity {
      users(filter: $filter) {
        twilio {
          voiceMessages {
            service
            url
            publicId
            signature
            resourceType
            etag
            createdAt
            messageType
          }
        }
      }
    }
  }
`;
