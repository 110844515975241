import { gql } from '@apollo/client';

export const GET_LEADS = gql`
  query GetLeads {
    crm {
      leads {
        id
        name
        email
        contactNo
        roofTypeId
        roofType {
          id
          roofTypeName
          createdAt
        }
        zipCode
        hasTaskSequence
        interestType {
          id
          interestTypeName
          createdAt
        }
        interestTypeId
        leadFormId
        leadForm {
          id
          formId
          description
          createdAt
        }
        assignedToId
        assignedTo {
          id
          email
          firstName
          lastName
        }
        assignedById
        assignedBy {
          id
          email
          firstName
          lastName
        }
        createdAt
        isContacted
        addressId
        address {
          id
          streetNo
          streetName
          city
          state
          zipCode
          country
          lat
          lng
          createdAt
        }
        appointments {
          id
          appointmentTypeId
          appointmentType {
            id
            appointmentTypeName
            displayName
            createdAt
          }
          appointmentLocationId
          appointmentLocation {
            id
            appointmentLocationName
            createdAt
          }
          startTime
          endTime
          createdById
          hasMeetingLink
          leadId
          appointmentRecipientId
          appointmentRecipient {
            id
            appointmentRecipientName
            createdAt
          }
          title
          description
          assignedToId
          eventId
          appointmentGuests {
            id
            name
            email
            appointmentId
            createdAt
          }
        }
        leadGuide {
          id
          title
          contentBody
          interestTypeId
          interestType {
            id
            interestTypeName
            createdAt
          }
          createdAt
        }
      }
    }
  }
`;

export const GET_LEAD = gql`
  query GetCrmLeadFilter($filter: CrmLeadFilter) {
    crm {
      leads(filter: $filter) {
        id
        name
        email
        contactNo
        address {
          id
          streetNo
          streetName
          city
          state
          zipCode
          country
          lat
          lng
          createdAt
        }
        appointments {
          id
          appointmentTypeId
          appointmentLocationId
          startTime
          endTime
          createdById
          hasMeetingLink
          leadId
          appointmentRecipientId
          title
          description
          assignedToId
          eventId
        }
        assignedToUser {
          id
          email
          firstName
          lastName
        }
        interestType {
          id
          interestTypeName
          createdAt
        }
        interestType {
          id
          interestTypeName
          createdAt
        }
      }
    }
  }
`;

export const GET_LEADS_LIST = gql`
  query GetLeadsList($filter: CrmLeadFilter, $pagination: PaginationInput) {
    crm {
      leadsPaged(filter: $filter, pagination: $pagination) {
        totalCount
        leads {
          id
          name
          status
          contactNo
          createdAt
          assignedToUser {
            firstName
            lastName
          }
          assignedByUser {
            firstName
            lastName
          }
          interestType {
            interestTypeName
          }
          leadForm {
            platform
          }
          address {
            streetNo
            streetName
            city
            state
            zipCode
          }
        }
      }
    }
  }
`;

export const GET_FILTERED_LEADS = gql`
  query GetFilteredLeads($filter: CrmLeadFilter) {
    crm {
      leads(filter: $filter) {
        id
        name
        email
        contactNo
        roofTypeId
        zipCode
        hasTaskSequence
        interestTypeId
        leadFormId
        assignedToId
        assignedById
        createdAt
        deletedAt
        isContacted
        addressId
        taskScheduleScaffoldGroupId
        hasTaskScaffolded
        roofType {
          id
          roofTypeName
          createdAt
        }
        interestType {
          id
          interestTypeName
          createdAt
        }
        leadForm {
          id
          formId
          platform
          description
          createdAt
        }
        leadGuide {
          id
          title
          contentBody
          interestTypeId
          createdAt
        }
        assignedTo {
          id
          email
          firstName
          lastName
        }
        assignedBy {
          id
          email
          firstName
          lastName
        }
        address {
          id
          streetNo
          streetName
          city
          state
          zipCode
          country
          lat
          lng
          createdAt
        }
        taskScheduleScaffoldGroup {
          id
          taskScheduleScaffoldGroupName
          interestTypeId
          createdAt
        }
        appointments {
          id
          appointmentTypeId
          appointmentType {
            id
            appointmentTypeName
            createdAt
          }
          appointmentLocationId
          appointmentLocation {
            id
            appointmentLocationName
            createdAt
          }
          startTime
          endTime
          createdById
          createdBy {
            id
            email
            firstName
            lastName
          }
          hasMeetingLink
          leadId
          appointmentRecipientId
          appointmentRecipient {
            id
            appointmentRecipientName
            createdAt
          }
          title
          description
          assignedToId
          eventId
          appointmentGuests {
            id
            name
            email
            appointmentId
            createdAt
          }
        }
        isCallableByAssignedUser
      }
    }
  }
`;

export const GET_LEADS_MENTION = gql`
  query GetLeadsMention($pagination: PaginationInput, $filter: CrmLeadFilter) {
    crm {
      leadsPaged(pagination: $pagination, filter: $filter) {
        leads {
          email
          name
          id
        }
        totalCount
      }
    }
  }
`;
