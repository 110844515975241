import { NotificationsOff } from '@mui/icons-material';
import { Box, List, Typography } from '@mui/material';
import React from 'react';
import { Calendar, PhoneMissed, Voicemail as VoicemailIcon } from 'react-feather';
import { CrmAppointment } from '../../../custom/types/crm/appointment';
import { CrmNotificationType } from '../../../custom/types/crm/notification';
import { DateFormatter, DateFormatType } from '../../../custom/components/DateFormatter';
import Notification from './Notification';
import { NotificationListType } from '../NavbarNotificationsDropdown';
import { ConversationTaskHistoryType } from '../../../custom/pages/task/taskTypes';
import TextHighlighter from '../utils/TextHighlighter';
import { IdentityContextType, IdentityNote } from '../../../custom/types/identity/user/note';
import { CrmUser } from '../../../custom/types/crm/user';
import { CrmCallRecording, mapCallRecordingCallerData } from '../../../custom/types/crm/callRecording';
import { CrmCallPayload, mapCallPayloadCallerData } from '../../../custom/types/crm/callPayload';
import { CrmLead } from '../../../custom/types/crm/lead';

const getInitials = (name: string) => {
  const names = name.split(' ');
  if (names.length === 1) return names[0][0].toUpperCase();
  return (names[0][0] + names[names.length - 1][0]).toUpperCase();
};

const NotificationList = ({ notifications, handleOnNotificationClick }: NotificationListType) => {
  if (!notifications || notifications.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="75vh"
        textAlign="center"
      >
        <NotificationsOff style={{ fontSize: 100, color: '#ccc' }} />
        <Typography variant="h6" style={{ marginTop: 16 }}>
          You've read all your notifications
        </Typography>
      </Box>
    );
  }

  const getCallerDisplayName = (
    data: { data: CrmCallPayload | CrmCallRecording } | null | undefined,
    notifType: CrmNotificationType.MISSED_CALL | CrmNotificationType.VOICEMAIL,
  ): string => {
    if (!data?.data?.callerData) return 'unknown';

    const callerType = data.data.callerData.__typename;
    const caller = (data.data as CrmCallRecording)?.dialer || (data.data as CrmCallPayload)?.caller || '';

    if (callerType === 'CrmUser') {
      const definedCaller = data.data.callerData as CrmUser;
      const user =
        notifType === CrmNotificationType.MISSED_CALL
          ? mapCallPayloadCallerData(definedCaller)
          : mapCallRecordingCallerData(definedCaller);
      return `${user?.userFirstName ?? ''} ${user?.userLastName ?? ''}`.trim() + (caller ? ` (${caller})` : '');
    }

    if (callerType === 'CrmLead') {
      const definedCaller = data.data.callerData as CrmLead;
      const lead =
        notifType === CrmNotificationType.MISSED_CALL
          ? mapCallPayloadCallerData(definedCaller)
          : mapCallRecordingCallerData(definedCaller);
      return `${lead?.leadName ?? ''} ${caller ? `(${caller})` : ''}`;
    }

    return 'unknown';
  };

  return (
    <List disablePadding>
      {notifications.map(item => {
        const { id, message, createdAt } = item;
        const formattedDate = DateFormatter({ date: createdAt, dateFormatType: DateFormatType.Standard });

        if (item.type === CrmNotificationType.CRM_APPOINTMENT) {
          const appointment = item.data as CrmAppointment;
          return (
            <Notification
              key={id}
              title={message || ''}
              description={formattedDate}
              Icon={Calendar}
              link={`/crm/appointments/${appointment.id}`}
              onClick={() => handleOnNotificationClick(item)}
              readAt={item.readAt}
            />
          );
        } else if (item.type === CrmNotificationType.TWILIO_CONVERSATION) {
          const conversation = item.data as ConversationTaskHistoryType;
          const initials = getInitials(JSON.parse(conversation.attributes)?.name || 'Unknown');
          const link = conversation.conversationSid ? `/crm/inbox/${conversation.conversationSid}` : `/crm/inbox`;
          return (
            <Notification
              key={id}
              title={message || ''}
              description={formattedDate}
              initials={initials}
              link={link}
              onClick={() => handleOnNotificationClick(item)}
              readAt={item.readAt}
            />
          );
        } else if (item.type === CrmNotificationType.IDENTITY_NOTE) {
          const user = item.user.entityContext as CrmUser;
          const note = item.data as IdentityNote;
          const defaultLink = note.context === IdentityContextType.TASK_SCHEDULE ? '/crm/tasks' : '/projects';

          return (
            <Notification
              key={id}
              title={<TextHighlighter message={message} />}
              initials={getInitials(`${user.firstName} ${user.lastName}`)}
              description={formattedDate}
              link={note.contextPath ?? defaultLink}
              notificationData={note}
              onClick={() => handleOnNotificationClick(item)}
              readAt={item.readAt}
            />
          );
        } else if (item.type === CrmNotificationType.VOICEMAIL) {
          const voicemail = item.data as CrmCallRecording;
          const link = `/crm/calls/voicemails/${voicemail.callSid}`;

          return (
            <Notification
              key={id}
              title={
                <TextHighlighter
                  message={`Voicemail from ${
                    voicemail.callerData
                      ? getCallerDisplayName({ data: voicemail }, CrmNotificationType.VOICEMAIL)
                      : voicemail.dialer ?? 'unknown'
                  }`}
                />
              }
              description={formattedDate}
              link={link}
              Icon={VoicemailIcon}
              onClick={() => handleOnNotificationClick(item)}
              readAt={item.readAt}
            />
          );
        } else if (item.type === CrmNotificationType.MISSED_CALL) {
          const missedCall = item.data as CrmCallPayload;
          const link = `/crm/calls/missed-calls/${missedCall.id}`;

          return (
            <Notification
              key={id}
              title={
                <TextHighlighter
                  message={`Missed Call from ${
                    missedCall.callerData
                      ? getCallerDisplayName({ data: missedCall }, CrmNotificationType.MISSED_CALL)
                      : missedCall.caller ?? 'unknown'
                  }`}
                />
              }
              description={formattedDate}
              link={link}
              Icon={PhoneMissed}
              onClick={() => handleOnNotificationClick(item)}
              readAt={item.readAt}
            />
          );
        } else {
          return null;
        }
      })}
    </List>
  );
};

export default NotificationList;
