/**
 * Checks if the user has the required permission.
 * @param userPermissions - Array of user's permissions
 * @param requiredPermission - Permission required to access a resource
 * @returns True if the user has the required permission, otherwise false
 */
const hasRequiredPermission = (userPermissions: string[], requiredPermission: string): boolean => {
  return userPermissions.includes(requiredPermission);
};

export default hasRequiredPermission;
