import { gql } from '@apollo/client';

export const CREATE_VOICE_CALL_RECORDING = gql`
  mutation CreateRecording($input: CrmCreateRecordingInput!) {
    crm {
      twilio {
        call {
          createRecording(input: $input) {
            success
          }
        }
      }
    }
  }
`;
