import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, IconButton, Popover as MuiPopover, Tooltip } from '@mui/material';
import { Bell } from 'react-feather';
import { CrmNotification, CrmNotificationType } from '../../custom/types/crm/notification';
import { useMutation } from '@apollo/client';
import { MARK_NOTIFICATION } from '../../custom/queries/crm/notification';
import { useSnackbar } from 'notistack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TabPanel as MuiTabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import NotificationList from './notifications/NotificationList';
import NotificationSkeleton from './notifications/NotificationSkeleton';
import NotificationPopoverHeader from './notifications/NotificationPopoverHeader';

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 540px;
    ${props => props.theme.shadows[1]};
    border: 1px solid ${props => props.theme.palette.divider};
  }
`;

const TabPanel = styled(MuiTabPanel)`
  padding: 0;
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${props => props.theme.header.indicator.background};
    color: ${props => props.theme.palette.common.white};
  }
`;

interface NavbarNotificationsDropdownProps {
  notifications?: CrmNotification[];
  isDataLoaded: boolean;
}

export type NotificationListType = {
  notifications: CrmNotification[] | undefined;
  handleOnNotificationClick: (notification: CrmNotification) => void;
};
const boxStyles = {
  maxWidth: '540px',
  backgroundColor: 'white',
  borderRadius: '5px',
  boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.15)',
  height: '90vh',
};

/**
 *
 */
function NavbarNotificationsDropdown(props: NavbarNotificationsDropdownProps) {
  const { notifications: notificationsProp, isDataLoaded = false } = props;
  const ref = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [showUnread, setShowUnread] = useState<boolean>(true);
  const [markNotification] = useMutation(MARK_NOTIFICATION);
  const [tabSelection, setTabSelection] = useState<number>(0);
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabSelection(newValue);
  };
  const [notifications, setNotifications] = useState<CrmNotification[] | undefined>();
  const [unReadNotifications, setUnReadNotifications] = useState<CrmNotification[] | undefined>();
  const [appointmentList, setAppointmentList] = useState<CrmNotification[] | undefined>();
  const [twilioConversations, setTwilioConversations] = useState<CrmNotification[] | undefined>();
  const [generalNotificationList, setGeneralNotificationList] = useState<CrmNotification[] | undefined>();
  const [voicemailList, setVoicemailList] = useState<CrmNotification[] | undefined>();
  const [missedCallList, setMissedCallList] = useState<CrmNotification[] | undefined>();

  useEffect(() => {
    if (notificationsProp && isDataLoaded) {
      setNotifications(notificationsProp);
      setUnReadNotifications(notificationsProp.filter(item => item.readAt === null));
      setAppointmentList(
        notificationsProp.filter(item => item.type === CrmNotificationType.CRM_APPOINTMENT && item.readAt === null),
      );
      setTwilioConversations(
        notificationsProp.filter(item => item.type === CrmNotificationType.TWILIO_CONVERSATION && item.readAt === null),
      );
      setGeneralNotificationList(
        notificationsProp.filter(item => item.type === CrmNotificationType.IDENTITY_NOTE && item.readAt === null),
      );
      setVoicemailList(
        notificationsProp.filter(item => item.type === CrmNotificationType.VOICEMAIL && item.readAt === null),
      );
      setMissedCallList(
        notificationsProp.filter(item => item.type === CrmNotificationType.MISSED_CALL && item.readAt === null),
      );
    }
  }, [notificationsProp, isDataLoaded]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnNotificationClick = async (selectedNotification: CrmNotification) => {
    try {
      // Toggle the read status based on the current readAt value
      const notificationState = selectedNotification.readAt === null ? 'READ' : 'UNREAD';

      markNotification({
        variables: {
          input: {
            notificationIds: selectedNotification.id,
            state: notificationState,
          },
        },
      });

      if (notificationState === 'READ') {
        const updatedNotifications = notifications?.map(item =>
          item.id === selectedNotification.id ? { ...item, readAt: '2024-06-04' } : item,
        );
        setNotifications(updatedNotifications);
      } else {
        const updatedNotifications = notifications?.map(item =>
          item.id === selectedNotification.id ? { ...item, readAt: null } : item,
        );
        setNotifications(updatedNotifications);
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
        persist: true,
        action: key => (
          <IconButton title="Close error" onClick={() => closeSnackbar(key)}>
            <CloseIcon color="disabled" />
          </IconButton>
        ),
      });
    }
  };

  const handleOnlyShowUnreadOnChange = (checked: boolean) => {
    setShowUnread(!showUnread);
    if (checked) {
      setAppointmentList(
        notifications?.filter(item => item.type === CrmNotificationType.CRM_APPOINTMENT && item.readAt === null),
      );
      setTwilioConversations(
        notifications?.filter(item => item.type === CrmNotificationType.TWILIO_CONVERSATION && item.readAt === null),
      );
      setGeneralNotificationList(
        notifications?.filter(item => item.type === CrmNotificationType.IDENTITY_NOTE && item.readAt === null),
      );
      setVoicemailList(
        notifications?.filter(item => item.type === CrmNotificationType.VOICEMAIL && item.readAt === null),
      );
      setMissedCallList(
        notifications?.filter(item => item.type === CrmNotificationType.MISSED_CALL && item.readAt === null),
      );
    } else {
      setAppointmentList(notifications?.filter(item => item.type === CrmNotificationType.CRM_APPOINTMENT));
      setTwilioConversations(notifications?.filter(item => item.type === CrmNotificationType.TWILIO_CONVERSATION));
      setGeneralNotificationList(notifications?.filter(item => item.type === CrmNotificationType.IDENTITY_NOTE));
      setVoicemailList(notifications?.filter(item => item.type === CrmNotificationType.VOICEMAIL));
      setMissedCallList(notifications?.filter(item => item.type === CrmNotificationType.MISSED_CALL));
    }
  };

  useEffect(() => {
    setUnReadNotifications(
      notifications?.filter(
        item =>
          item.readAt === null &&
          (item.type === CrmNotificationType.CRM_APPOINTMENT ||
            item.type === CrmNotificationType.TWILIO_CONVERSATION ||
            item.type === CrmNotificationType.IDENTITY_NOTE ||
            item.type === CrmNotificationType.VOICEMAIL ||
            item.type === CrmNotificationType.MISSED_CALL),
      ),
    );

    if (showUnread) {
      setAppointmentList(
        notifications?.filter(item => item.type === CrmNotificationType.CRM_APPOINTMENT && item.readAt === null),
      );
      setTwilioConversations(
        notifications?.filter(item => item.type === CrmNotificationType.TWILIO_CONVERSATION && item.readAt === null),
      );
      setGeneralNotificationList(
        notifications?.filter(item => item.type === CrmNotificationType.IDENTITY_NOTE && item.readAt === null),
      );
      setVoicemailList(
        notifications?.filter(item => item.type === CrmNotificationType.VOICEMAIL && item.readAt === null),
      );
      setMissedCallList(
        notifications?.filter(item => item.type === CrmNotificationType.MISSED_CALL && item.readAt === null),
      );
    } else {
      setAppointmentList(notifications?.filter(item => item.type === CrmNotificationType.CRM_APPOINTMENT));
      setTwilioConversations(notifications?.filter(item => item.type === CrmNotificationType.TWILIO_CONVERSATION));
      setGeneralNotificationList(notifications?.filter(item => item.type === CrmNotificationType.IDENTITY_NOTE));
      setVoicemailList(notifications?.filter(item => item.type === CrmNotificationType.VOICEMAIL));
      setMissedCallList(notifications?.filter(item => item.type === CrmNotificationType.MISSED_CALL));
    }
  }, [notifications, showUnread]);

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={isDataLoaded && unReadNotifications ? unReadNotifications.length : 0}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box pt={5} px={4} sx={boxStyles}>
          <NotificationPopoverHeader
            showUnread={showUnread}
            handleOnlyShowUnreadOnChange={handleOnlyShowUnreadOnChange}
          />
          <TabContext value={tabSelection.toString()}>
            <Tabs
              value={tabSelection}
              onChange={handleChange}
              scrollButtons="auto"
              sx={{ borderBottom: '1px solid #ECECEE', p: 0 }}
            >
              <Tab label={'Inbox'} />
              <Tab label={'Appointments'} />
              <Tab label={'Voicemails'} />
              <Tab label={'Missed Calls'} />
              <Tab label={'General'} />
            </Tabs>
            <TabPanel value="0">
              {isDataLoaded ? (
                <NotificationList
                  notifications={twilioConversations}
                  handleOnNotificationClick={handleOnNotificationClick}
                />
              ) : (
                <NotificationSkeleton />
              )}
            </TabPanel>
            <TabPanel value="1">
              {isDataLoaded ? (
                <NotificationList
                  notifications={appointmentList}
                  handleOnNotificationClick={handleOnNotificationClick}
                />
              ) : (
                <NotificationSkeleton />
              )}
            </TabPanel>
            <TabPanel value="2">
              {isDataLoaded ? (
                <NotificationList notifications={voicemailList} handleOnNotificationClick={handleOnNotificationClick} />
              ) : (
                <NotificationSkeleton />
              )}
            </TabPanel>
            <TabPanel value="3">
              {isDataLoaded ? (
                <NotificationList
                  notifications={missedCallList}
                  handleOnNotificationClick={handleOnNotificationClick}
                />
              ) : (
                <NotificationSkeleton />
              )}
            </TabPanel>
            <TabPanel value="4">
              {isDataLoaded ? (
                <NotificationList
                  notifications={generalNotificationList}
                  handleOnNotificationClick={handleOnNotificationClick}
                />
              ) : (
                <NotificationSkeleton />
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
