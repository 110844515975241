import { gql } from '@apollo/client';

export const GET_CALL_RECORDINGS = gql`
  query CallRecordings($filter: CrmCallRecordingFilter) {
    crm {
      userOptions {
        callRecordings(filter: $filter) {
          id
          callSid
          receiver
          dialer
          duration
          summary
          startTime
          mediaUrl
          createdAt
          endTime
          type
        }
      }
    }
  }
`;

export const GET_CALL_RECORDINGS_PAGED = gql`
  query CallRecordingsPaged($pagination: PaginationInput, $filter: CrmCallRecordingFilter) {
    crm {
      userOptions {
        callRecordingsPaged(pagination: $pagination, filter: $filter) {
          totalCount
          callRecordings {
            id
            callSid
            receiver
            dialer
            duration
            summary
            startTime
            mediaUrl
            createdAt
            endTime
            type
            callerData {
              ... on CrmLead {
                contactNo
                name
                __typename
              }
              ... on CrmUser {
                firstName
                lastName
                twilio {
                  projectedAddress
                }
                __typename
              }
            }
            receiverData {
              ... on CrmLead {
                name
                contactNo
                __typename
              }
              ... on CrmUser {
                lastName
                firstName
                twilio {
                  projectedAddress
                }
                __typename
              }
            }
          }
        }
      }
    }
  }
`;
