import React from 'react';
import { matchPath } from 'react-router-dom';
import { SidebarItemsType } from '../../types/sidebar';
import SidebarNavListItem from './SidebarNavListItem';
import SidebarNavList from './SidebarNavList';

type ReduceChildRoutesProps = {
  depth: number;
  page: SidebarItemsType;
  items: JSX.Element[]; // This is still an array of React elements
  currentRoute: string;
  userPermissions: string[];
};

/**
 *
 * @param props
 */
const reduceChildRoutes = (props: ReduceChildRoutesProps) => {
  const { items, page, depth, currentRoute, userPermissions } = props;

  // Check if the user has the required permissions for the page
  const hasPermission = page.permissions
    ? page.permissions.some(permission => userPermissions.includes(permission))
    : true;

  // If the page has children, check each child for permissions
  if (page.children) {
    const childItems: SidebarItemsType[] = []; // Array of SidebarItemsType
    let hasChildPermission = false;

    // Iterate through children and check permissions
    page.children.forEach(child => {
      const childProps: ReduceChildRoutesProps = {
        depth: depth + 1,
        page: child,
        items: [], // We don't need items for children here
        currentRoute,
        userPermissions,
      };

      // Call reduceChildRoutes for each child
      const validChildren = reduceChildRoutes(childProps);

      // If the child has any valid items, it means it has permission
      if (validChildren.length > 0) {
        hasChildPermission = true;
        childItems.push(child); // Push the raw child item, not a JSX element
      }
    });

    // If none of the children have permissions, do not render the parent item
    if (!hasChildPermission) {
      return items; // Skip rendering this item
    }

    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute,
        )
      : false;

    // Render the parent item with its children if there are any valid children
    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList depth={depth + 1} pages={childItems} />
      </SidebarNavListItem>,
    );
  } else {
    // If it's a leaf node (no children), check permissions directly
    if (hasPermission) {
      items.push(
        <SidebarNavListItem
          depth={depth}
          href={page.href}
          icon={page.icon}
          key={page.title}
          badge={page.badge}
          title={page.title}
        />,
      );
    }
  }

  return items;
};

export default reduceChildRoutes;
