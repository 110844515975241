import { gql } from '@apollo/client';

export const CONTACT_PROFILE = gql`
  query ContactProfile($filter: CrmLeadFilter) {
    crm {
      leads(filter: $filter) {
        id
        name
        assignedTo {
          id
          email
          firstName
          lastName
        }
        appointments {
          startTime
          endTime
          appointmentLocation {
            appointmentLocationName
          }
          appointmentGuests {
            email
            name
          }
          createdBy {
            firstName
            lastName
          }
          assignedTo {
            firstName
            lastName
          }
        }
        address {
          city
          state
          streetName
          streetNo
          zipCode
          lat
          lng
        }
        contactNo
        email
        createdAt
        interestType {
          interestTypeName
        }
        leadForm {
          platform
        }
        conversation {
          conversationThread {
            messages {
              conversationSid
              body
              author
              dateCreated
              isMe
              sender
              attributes
            }
          }
          participants {
            attributes
            identity
          }
          emails {
            body
            isMe
            createdAt
            receiver
            sender
            subject
          }
          friendlyName
          recordings {
            mediaUrl
            createdAt
          }
        }
        project {
          id
          customer {
            firstName
            lastName
            address
          }
        }
        taskSchedules {
          createdAt
          assignedTo {
            lastName
            firstName
          }
          dueDate
          remarks
          taskTitle
          taskType {
            taskTypeName
            displayName
          }
          isAutomated
          dispositionReason
          disposition {
            dispositionName
          }
          createdBy {
            firstName
            lastName
          }
        }
        callRecordings {
          dialer
          receiver
          duration
          summary
          startTime
          mediaUrl
          createdAt
          endTime
        }
      }
    }
  }
`;
