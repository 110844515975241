import React from 'react';
import { useLocation } from 'react-router-dom';

import { SidebarItemsType } from '../../types/sidebar';
import reduceChildRoutes from './reduceChildRoutes';
import { UserIdentityContext } from '../../custom/contexts/UserIdentityContext';

type SidebarNavListProps = {
  depth: number;
  pages: SidebarItemsType[];
  setSidebarNavListCount?: React.Dispatch<React.SetStateAction<number>>;
};

/**
 *
 * @param props
 */
const SidebarNavList: React.FC<SidebarNavListProps> = props => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;
  const { userIdentity } = React.useContext(UserIdentityContext) || {};

  const childRoutes = pages.reduce(
    (items, page) =>
      reduceChildRoutes({
        items,
        page,
        currentRoute,
        depth,
        userPermissions: userIdentity?.permissions?.map(permission => permission.name) ?? [''],
      }),
    [] as JSX.Element[],
  );

  if (props.setSidebarNavListCount) {
    props.setSidebarNavListCount(childRoutes.length);
  }

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
