import { gql } from '@apollo/client';

export const GET_DISPOSITIONS = gql`
  query GetDispositions($filter: CrmDispositionFilter) {
    crm {
      preferences {
        dispositions(filter: $filter) {
          id
          dispositionName
          isEventTriggered
          fromTable
          cluster
          createdAt
          workflowId
        }
      }
    }
  }
`;
