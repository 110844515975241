import React from 'react';
import { Grid, Switch, Typography } from '@mui/material';

interface PopoverHeaderProps {
  showUnread: boolean;
  handleOnlyShowUnreadOnChange: (checked: boolean) => void;
}
const NotificationPopoverHeader: React.FC<PopoverHeaderProps> = ({ showUnread, handleOnlyShowUnreadOnChange }) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" pb={3}>
      <Grid item>
        <Typography variant="h3">Notifications</Typography>
      </Grid>
      <Grid item>
        Only show unread{' '}
        <Switch checked={showUnread} onChange={(_, checked) => handleOnlyShowUnreadOnChange(checked)} />
      </Grid>
    </Grid>
  );
};

export default NotificationPopoverHeader;
